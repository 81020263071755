import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import { isIOS, isMobile } from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from 'components/TopNav';

class App extends React.Component {

  render() {
    const { auth, match, drawerType, navigationStyle, horizontalNavPosition } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height')
    }
    else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height')
    }

    return (
      <div className={`app-container ${drawerStyle}`}>

        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
              <TopNav styleName="app-top-header" />}
            <Header />
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
              <TopNav />}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route path={`${match.url}/addOrder`}
                  component={asyncComponent(() => import('./routes/AddOrder'))} />
                <Route path={`${match.url}/orders`}
                  component={asyncComponent(() => import('./routes/Orders'))} />
                <Route path={`${match.url}/ordersToDelivery`}
                  component={asyncComponent(() => import('./routes/OrdersToDelivery'))} />
                <Route path={`${match.url}/products`}
                  component={asyncComponent(() => import('./routes/Products'))} />
                <Route path={`${match.url}/archivedOrders`}
                  component={asyncComponent(() => import('./routes/ArchivedOrders'))} />
                <Route path={`${match.url}/specialOrders`}
                  component={asyncComponent(() => import('./routes/SpecialOrders'))} />
                <Route path={`${match.url}/users`}
                  component={asyncComponent(() => import('./routes/Users'))} />
                <Route path={`${match.url}/settings`}
                  component={asyncComponent(() => import('./routes/Settings'))} />
                {auth.role && auth.authUser == "QZBTiql4hbOBWg4tD1vlJF4nEDn1" && <Route path={`${match.url}/administration`}
                  component={asyncComponent(() => import('./routes/Administration'))} />}
                <Route component={asyncComponent(() => import('components/Error404'))} />
              </Switch>
            </div>
            <Footer />
          </main>
        </div>
      </div>
    );
  }
}


const mapStateToProps = ({ settings, auth }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { auth, drawerType, navigationStyle, horizontalNavPosition }
};
export default withRouter(connect(mapStateToProps)(App));
