import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import "firebase/storage";

// Initialize Firebase
const config = {
  apiKey: 'AIzaSyA6WlLJKrBOqarteEnG5Vv8Tp-EOrULdaM',
  authDomain: 'sosapp-ff3d2.firebaseapp.com',
  databaseURL: 'https://sosapp-ff3d2.firebaseio.com',
  projectId: 'sosapp-ff3d2',
  storageBucket: 'gs://sosapp-client-images',
  messagingSenderId: '126008488775',
};

firebase.initializeApp(config);


const firebaseAuth = firebase.auth();
const database = firebase.firestore();
const functions = firebase.functions();
var storage = firebase.storage();

export {
  firebaseAuth,
  functions,
  database,
  storage
};
